import { Carousel } from "react-responsive-carousel";
import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p>
        </div>
        <div className="row w-100">
          <Carousel>
            {props.data?.slice(0, 3)?.map((d, i) => (
              <div key={`${d.title}-${i}`}>
                <Image
                  title={d.title}
                  smallImage={d.largeImage}
                  style={{
                    width: "100vw",
                  }}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
