import React from "react";

import { ReactComponent as AppstoreLogo } from "../assets/img/download/appstore.svg";
import { ReactComponent as PlaystoreLogo } from "../assets/img/download/playstore.svg";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <div
                  className="row"
                  style={{
                    justifyContent: "space-evenly",
                    display: "flex",
                    alignItems: "center",
                    maxHeight: 72,
                  }}
                >
                  <a href="#" alt="">
                    <AppstoreLogo width={144} />
                  </a>
                  <a href="#" alt="">
                    <PlaystoreLogo width={144} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
